import { BillingDetail } from "../billingSchedule/billing-type";
import { MbOrgDetail } from "../organization/org-types";
import { Address, CommonDateNUser, MediaFile, MeetingRoom, Order, PakringLot, Select } from "../public-types";

enum SpaceProductType {
  FULL_COURT = "FULL_COURT",
  OPEN_COURT = "OPEN_COURT",
  TIME_COURT = "TIME_COURT",
}

enum ContractStep {
  // 신청접수
  APPLY_RECEIVED = "APPLY_RECEIVED",
  // 신청취소
  APPLY_CANCEL = "APPLY_CANCEL",
  // 신청확인
  APPLY_CONFIRM = "APPLY_CONFIRM",
  // 계약예정
  CONTRACT_PLAN = "CONTRACT_PLAN",
  // 계약체결
  CONTRACT_ACCEPT = "CONTRACT_ACCEPT",
  // 이용승인
  USE_APPROVAL = "USE_APPROVAL",
  // 이용중
  USE_PROGRESS = "USE_PROGRESS",
  // 이용완료
  USE_COMPLETE = "USE_COMPLETE",
  // 해지접수
  TERMINATE_RECEIVED = "TERMINATE_RECEIVED",
  // 해지완료
  TERMINATE_COMPLETE = "TERMINATE_COMPLETE",
}

const searchStatusOption: Select[] = [
  { label: "전체", value: "" },
  // { label: "신청접수", value: ContractStep.APPLY_RECEIVED },
  { label: "신청취소", value: ContractStep.APPLY_CANCEL },
  { label: "신청확인", value: ContractStep.APPLY_CONFIRM },
  { label: "계약예정", value: ContractStep.CONTRACT_PLAN },
  { label: "계약체결", value: ContractStep.CONTRACT_ACCEPT },
  { label: "이용승인", value: ContractStep.USE_APPROVAL },
  { label: "이용중", value: ContractStep.USE_PROGRESS },
  { label: "이용완료", value: ContractStep.USE_COMPLETE },
  { label: "해지접수", value: ContractStep.TERMINATE_RECEIVED },
  { label: "해지완료", value: ContractStep.TERMINATE_COMPLETE },
];

enum ApplicationName {
  //신청/계약(계약) id
  CONTRACT_ID = "CONTRACT_ID",
  //공간상품 id
  SPACE_PRODUCT_ID = "SPACE_PRODUCT_ID",
  //공간상품명
  SPACE_PRODUCT_NAME = "SPACE_PRODUCT_NAME",
  //신청/계약자명
  APPLICANT_NAME = "APPLICANT_NAME",
  //신청/계약자명
  CONTRACT_MEMBER_NO = "CONTRACT_MEMBER_NO",
  //신청/계약자 법인명/상호
  APPLICANT_CORPORATION = "APPLICANT_CORPORATION",
  CONTRACT_APPLY_NUMBER = "CONTRACT_APPLY_NUMBER",
  CONTRACT_NUMBER = "CONTRACT_NUMBER",
  MB_ORGANIZATION_ID = "MB_ORGANIZATION_ID",
}

const searchTypeOption: Select[] = [
  { label: "전체", value: "" },
  { label: "신청/계약 id", value: ApplicationName.CONTRACT_ID },
  { label: "상품id", value: ApplicationName.SPACE_PRODUCT_ID },
  { label: "상품명", value: ApplicationName.SPACE_PRODUCT_NAME },
  { label: "계약자명", value: ApplicationName.APPLICANT_NAME },
  { label: "신청 번호", value: ApplicationName.CONTRACT_APPLY_NUMBER },
];

const searchDateTypeOption: Select[] = [
  { label: "이용일", value: "" },
  { label: "시작일", value: "CONTRACT_START_TIME" },
  { label: "종료일", value: "CONTRACT_END_TIME" },
];

enum MediaServiceType {
  // 정의되지 않은 타입
  MEDIASERVICE_UNRECOGNIZED = "MEDIASERVICE_UNRECOGNIZED",
  // 건물
  BUILDING = "BUILDING",
  // 건물 층
  BUILDING_FLOOR = "BUILDING_FLOOR",
  // 건물 호실
  BUILDING_ROOM = "BUILDING_ROOM",
  // 회의실
  BUILDING_MEETING_ROOM = "BUILDING_MEETING_ROOM",
  // 데스크공간
  BUILDING_DESK_SPACE = "BUILDING_DESK_SPACE",
  // 리프레쉬공간
  BUILDING_REFRESH = "BUILDING_REFRESH",
  // 상품서비스
  PRODUCT = "PRODUCT",
  // 공간상품평면도
  PRODUCT_FLOOR_PLAN = "PRODUCT_FLOOR_PLAN",
  // 공간상품 시설/공용공간
  PRODUCT_FACILITY = "PRODUCT_FACILITY",
}

//목록 검색
interface SearchParams {
  id?: number;
  contractStep?: string;
  searchType?: string;
  searchValue?: string;
  //검색일(from) YYYY-MM-DD
  searchStartTime?: string;
  //검색일(to) YYYY-MM-DD
  searchEndTime?: string;
  contractIds?: string;
  contractManageIds?: string;
  page?: number;
  size?: number;
  searchTimeType?: string;
  sort?: {
    orders: Array<Order>;
  };
}

interface ContractManageList {
  contractManageId: number;
  contractId: number;
  spaceProductName: string;
  spaceProductId: number;
  contractName: string;
  applicantName: string;
  applicantCorporation: string;
  contractStartTime: string;
  contractEndTime: string;
  contractTerm: string;
  createdDate: string;
  modifiedDate: string;
  contractStep: string;
  contractMemberNo: string;
  contractMemberId: string;
  contractApplyNumber: string;
  contractNumber: string;
  contractDate: string;
  mbOrganizationName?: string;
}

//CCA19. 계약(이용) 중인 신청/계약 목록 조회

interface ContractUseRequestModel {
  contractManageId: number;
  contractApplyNumber: string;
  contractId: number;
  contractMemberId: number;
  memberNo: string;
  spaceProductId: number;
  spaceProductType: string;
  spaceProductName: string;
  contractStep: string;
  useTerm: number;
  useStartTime: string;
  useEndTime: string;
  contractTerm: number;
  contractStartTime: string;
  contractEndTime: string;
  inviteDate: string;
  isContractor: boolean;
  isAdmin: boolean;
  memberTotCount: number;
  memberMaxNums: number;
}

interface ContractUseRequestList {
  content: Array<ContractUseRequestModel>;
}

interface ContractDetailBasicInfo {
  contractId: number;
  contractManageId: number;
  contractApplyNumber?: string;
  contractStep: string;
  createdDate: string;
  modifiedDate: string;
  modifiedBy?: string;
}

//계약서
interface CtMediaFile {
  id: number;
  serviceId: string;
  serviceType: string;
  serviceTag: string;
  mediaType: string;
  originMediaName: string;
  description: string;
  mediaPath: string;
  isPrimary: boolean;
  orderNums: number;
  isDeleted: boolean;
}

//계약상품
interface CotractProduct {
  buildingUid: number;
  buildingName: string;
  description: string;
  floorNums: number;
  undergroundFloorNums: number;
  feature: string;
  completionYmd: string;
  entryStartTime: string;
  entryEndTime: string;
  passengerElev: number;
  freightElev: number;
  fileList: Array<MediaFile>;
  meetingRoomList: Array<MeetingRoom>;
  parkingLotList: Array<PakringLot>;
  address: Address;
}

enum MemberStatus {
  //초대
  MEMBER_INVITE = "MEMBER_INVITE",
  //사용
  MEMBER_USE = "MEMBER_USE",
  //삭제
  MEMBER_DELETE = "MEMBER_DELETE",
  //종료
  MEMBER_CLOSE = "MEMBER_CLOSE",
}

//이용자
interface CtMember {
  contractMemberId: number;
  contractManageId: number;
  inviteMobileNumber: number;
  memberNo: number;
  isContractor: boolean;
  isAdmin: boolean;
  inviteDate: string;
  memberStatus: string;
  useTime: string;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

enum VisitStatus {
  // 사용
  VISIT_USE = "VISIT_USE",
  // 일시정지
  VISIT_PAUSE = "VISIT_PAUSE",
  // 삭제
  VISIT_DELETE = "VISIT_DELETE",
  // 종료
  VISIT_CLOSE = "VISIT_CLOSE",
}
// 방문자 관리
interface CtVisitor {
  visitorId: number;
  contractManageId: number;
  visitorName: string;
  visitorMobileNumber: string;
  visitorCarNumber: string;
  visitStartTime: string;
  visitEndTime: string;
  visitStatus: string;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

//계약관리
interface CtContractManage {
  contractManageId: number;
  contractId: number;
  contractApplyType: string;
  useStartTime: string;
  useEndTime: string;
  applicantName: string;
  applicantCorporation: string;
  isAgreeTerms: boolean;
  businessRegNumber: string;
  spaceProductId: number;
  spaceProductName: string;
  accessGroupId: number;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

//계약 메모
interface CtContractMemo {
  contractMemoId: number;
  contractManageId: number;
  contractMemo: string;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

// 계약
interface CtContract {
  contractId: number;
  contractManageId: number;
  contractStep: string;
  contractDate: string;
  contractTerm: number;
  contractStartTime: string;
  contractEndTime: string;
  depositAmount: string;
  earnestAmount: string;
  earnestPayStatus: string;
  balanceAmount: string;
  balancePayStatus: string;
  isContractCancel: boolean;
  contractCancelDate: string;
  contractCancelStartOrder: number;
  contractCancelReason: string;
  contractBillTimeType: string;
  contractPayType?: string;
  pgcode: string;
  payAmount: string;
  payRegularDate: number;
  billkey: string;
  taxBillReceiveEmail: string;
  taxBillChargeAmount: string;
  taxBillManageAmount: string;
  taxBillRegularDate: number;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

enum PayStatus {
  //결제 대기
  PAY_WAIT = "PAY_WAIT",
  //결제 완료
  PAY_COMPLETE = "PAY_COMPLETE",
}

enum BillTimeType {
  //즉시결제
  IMMEDIATE_PAYMENT = "IMMEDIATE_PAYMENT",
  //정기결제
  REGULAR_PAYMENT = "REGULAR_PAYMENT",
}

enum PayType {
  // 일반결제형
  PAY = "PAY",
  // 세금계산서 발행
  TAX = "TAX",
}

enum PgCodeType {
  // 신용카드
  CREADIT_CARD = "creditcard",
  //가상계좌
  VIRTUAL_ACCOUNT = "virtualaccount",
}

interface CtBill {
  billId: number;
  contractId: number;
  billNumber: string;
  billTimeType: string;
  billPayType: string;
  billCustomerName: string;
  billOrder: number;
  billIssueDate: string;
  billStartTime: string;
  billEndTime: string;
  beforeDiscountAmount: number;
  discountAmount: number;
  billAmount: number;
  billPayStatus: string;
  isDeleted: boolean;
}

interface CtPayment {
  payId: number;
  billId: number;
  pgcode: string;
  clientId: string;
  serviceName: string;
  userId: string;
  userName: string;
  orderNo: string;
  amount: string;
  taxfreeAmount: string;
  taxAmount: string;
  productName: string;
  emailFlag: boolean;
  emailAddr: string;
  autopayFlag: boolean;
  receiptFlag: boolean;
  keyinFlag: boolean;
  customParameter: string;
  returnUrl: string;
  callbackUrl: string;
  cancelUrl: string;
  inappFlag: string;
  appReturnUrl: string;
  appCancelUrl: string;
  token: string;
  onlineUrl: string;
  mobileUrl: string;
  billkey: string;
  ipAddr: string;
  tid: string;
  cid: string;
  payInfo: string;
  domesticFlag: boolean;
  transactionDate: string;
  installMonth: number;
  cardInfo: string;
  payhash: string;
  code: string;
  message: string;
}

interface CtVirtualAcount {
  payId: number;
  accountNo: string;
  accountName: string;
  accountHolder: string;
  bankCode: string;
  bankName: string;
  issueTid: string;
  expireDate: string;
  expireTime: string;
}

interface CtCashReceipt {
  payId: number;
  cashReceiptCid: string;
  cashReceiptDealNo: string;
  cashReceiptIssueType: boolean;
  cashReceiptPayerSid: string;
  cashReceiptType: string;
}

interface CtPaymentCancel {
  cancelId: number;
  payId: number;
  userId: string;
  ipAddr: string;
  tid: string;
  cid: string;
  cancelAmount: string;
  partialAmount: string;
  taxfreeAmount: string;
  cancelDate: string;
  code: string;
  message: string;
}

export type {
  CtMember,
  SearchParams,
  CtMediaFile,
  CotractProduct,
  CtVisitor,
  CtContractManage,
  CtContractMemo,
  CtContract,
  CtBill,
  CtPayment,
  CtVirtualAcount,
  CtCashReceipt,
  CtPaymentCancel,
  ContractManageList,
  ContractDetailBasicInfo,
  ContractUseRequestList,
};
export {
  SpaceProductType,
  ContractStep,
  ApplicationName,
  MediaServiceType,
  MemberStatus,
  VisitStatus,
  PayStatus,
  BillTimeType,
  PayType,
  PgCodeType,
  searchStatusOption,
  searchTypeOption,
  searchDateTypeOption,
};

export interface ContractManage {
  buildingId: string;
  buildingLocationCode: string;
  contractApplyNumber: string;
  contractManageId: string;
  contractId?: string;
  createdBy: string;
  createdDate: string;
  isDeleted: string;
  isMntProduct: string;
  mbOrganizationId: string;
  modifiedBy: string;
  modifiedDate: string;
  partnerId: string;
  providerId: string;
  spaceProductId: string;
  spaceProductName: string;
  spaceProductType: string;
}

export interface GetContractManagementInformationParams {
  contractApplyNumber: string;
}

export interface GetContractManagementInformationResponse {
  contractManage: ContractManage;
  contractId: number;
}

export interface GetContractManageResponse {
  contractId: number;
  contractManage: ContractManage;
}

export type ProductManageCalendarType = "NONE" | "GC" | "OC";

/** 계약-운영정보- productManage type*/
export interface ContractOperationProductManage {
  calendarType?: string;
  clientId?: string;
  clientSecretValue?: string;
  createdBy?: string;
  createdDate?: string;
  deskDailyTime?: number;
  deskTotalTime?: number;
  dummyUserEmail?: string;
  isDeleted?: boolean;
  isUseCalendar?: boolean;
  isUseDesk?: boolean;
  isUseMeetingRoom?: boolean;
  isUseOmega?: boolean;
  isUseRefreshRoom?: boolean;
  isUseVisitorInvite?: boolean;
  meetingRoomDailyTime?: number;
  meetingRoomTotalTime?: number;
  memberMaxNums?: number;
  memberNums?: number;
  modifiedBy?: string;
  modifiedDate?: string;
  refreshRoomDailyTime?: number;
  refreshRoomTotalTime?: number;
  tenantId?: string;
}

export interface ProductFacilityType {
  contractFacilityId: string;
  id: string;
  buildingId: string;
  buildingName: string;
  commonFacilityType: string;
  groupName: string;
  floorNum: number;
  isGround: boolean;
  facilityName: string;
  facilityMaxPeopleNums: number;
  description: string;
  eslAddress: string;
  resourceEmail: string;
  isDeleted: boolean;
}

export interface ContractProductManageModel {
  productFacility: Array<ProductFacilityType>;
}

/**
 * 2023-09-07 이후 변경된 API대응을 위한 type 정의
 * 상세 페이지 관련 타입 정의
 * ========================================
 */

export interface DetailTotalData {
  contract?: ContractDetail;
  contractManage?: ContractManagaDetail;
  scheduleList?: Array<BillingDetail>;
  contractOrganization?: MbOrgDetail;
  product?: any;
  provider?: any;
  room?: any;
  expenseProofData?: ExpenseProofRequest | null;
}

export interface SaveContractModel {
  contractManage: {
    partnerId: number;
    mbOrganizationId?: number;
    spaceProductId: number;
    spaceProductType: string;
    spaceProductName: string;
  };
  contract: {
    contractApplyType?: string;
    useStartTime: string;
    useEndTime: string;
    useTerm: number;
    applicantName?: string;
    applicantCorporation?: string;
    isAgreeTerms?: boolean;
  };
  contractMember: {
    memberNo: string;
    inviteMobileNumber: string;
  };
}
export interface ContractDetailWrap {
  contract?: ContractDetail;
}
export interface ContractDetail extends CommonDateNUser {
  contractId?: string | number;
  contractName?: string;
  contractManageId?: string | number;
  contractNumber?: string;
  contractStep?: string;
  contractApplyType?: string;
  useStartTime?: string;
  useEndTime?: string;
  useTerm?: number;
  applicantName?: string;
  applicantCorporation?: string;
  isAgreeTerms?: boolean;
  contractDate?: string;
  contractTerm?: number;
  contractStartTime?: string;
  contractEndTime?: string;
  depositAmount?: number;
  earnestAmount?: number;
  earnestPayStatus?: string;
  balanceAmount?: number;
  balancePayStatus?: string;
  contractBillTimeType?: string;
  pgcode?: string;
  payAmount?: number;
  taxAmount?: number;
  totalAmount?: number;
  isMntContain?: boolean;
  isDeleted?: boolean;
  eSignList?: [];
  billContactList?: Array<ContractorNoti>;
  mediaList?: Array<ContractMedia>;
  [key: string]: any;
}

export enum EsignType {
  Complete = "Complete",
  Canceled = "Canceled",
  Playing = "Playing",
  Truncate = "Truncate",
  Disposal = "Disposal",
}
export enum WorkflowType {
  WEBTYPE = "WEBTYPE",
  NORMAL = "NORMAL",
  FACEWEB = "FACEWEB",
  BULKTYPE = "BULKTYPE",
  GUIDE = "GUIDE",
}

export interface ESignListDetail extends CommonDateNUser {
  esignId?: number;
  contractId?: number;
  workflowName?: string;
  workflowId?: number;
  status?: EsignType;
  esignDetail?: ESignDetail;
}

export interface ESignDetail {
  workflowId?: number;
  workflowName?: string;
  starterName?: string;
  starterEmail?: string;
  workflowType?: WorkflowType;
  status?: EsignType;
  createdDate?: string;
  lastModifiedDate?: string;
  expiryDate?: string;
  businessId?: number;
  businessName?: string;
  order?: number;
  totalOrderCount?: number;
  certificateUrl?: string;
  previewUrl?: string;
  playingUrl?: string;
  downloadUrl?: string;
  history: Array<ESignHistory>;
  recipient: Array<ESignRecipient>;
  referer: Array<ESignReferer>;
  field: Array<ESignField>;
}
export interface ESignHistory {
  order?: number;
  name?: string;
  email?: string;
  comment?: string;
  createdDate?: string;
  status?: string;
}
export interface ESignRecipientAPIFormat {
  order: number;
  myTurn?: string;
  name: string;
  email: string;
  enableCertMobile?: string;
  mobile?: string;
  enableCertPassword?: string;
  password?: string;
  passwordHint?: string;
}
export interface ESignRecipient {
  order?: number;
  myTurn?: string;
  name?: string;
  email?: string;
  enableCertMobile?: string;
  mobile?: string;
  enableCertPassword?: string;
  password?: string;
  passwordHint?: string;
  [key: string]: any;
}
export interface ESignReferer {
  name?: string;
  email?: string;
  type?: string;
  [key: string]: any;
}
export interface ESignField {
  order?: number;
  name?: string;
  value?: string;
  email?: string;
  [key: string]: any;
}

export interface Template {
  templateId?: number;
  templateName?: string;
  templateType?: string;
  creatorEmail?: string;
  createdDate?: string;
  lastModifierEmail?: string;
  lastModifiedDate?: string;
  totalOrderCount?: number;
  templateWorkflowCount?: number;
  important?: boolean;
  folderId?: string;
  folderPath?: string;
  [key: string]: any;
}

export interface TemplateInfo {
  templateId?: number;
  templateName?: string;
  templateType?: string;
  creatorEmail?: string;
  createdDate?: string;
  lastModifierEmail?: string;
  lastModifiedDate?: string;
  totalOrderCount?: number;
  templateWorkflowCount?: number;
  important?: boolean;
  folderId?: string;
  folderPath?: string;
  previewUrl?: string;
  field: Array<TemplateField>;
  [key: string]: any;
}

export interface TemplateField {
  order?: number;
  fieldName?: string;
  fieldOwner?: string;
  [key: string]: any;
}
export interface ContractorNoti extends CommonDateNUser {
  billContactId?: string | number;
  receiveNotiType?: string;
  contact?: string;
  email?: string;
  description?: string;
  isDeleted?: boolean;
  [key: string]: any;
}

export interface ContractMedia extends CommonDateNUser {
  id?: string | number;
  serviceId?: string | number;
  serviceType?: string;
  serviceTag?: string;
  isPrimary?: boolean;
  orderNums?: number;
  contentType?: string;
  filename?: string;
  key?: string;
  fileSize?: string;
  fileStorageType?: string;
  isDeleted?: boolean;
  [key: string]: any;
}

export interface ContractManagaDetailWrap {
  contractManage?: ContractManagaDetail;
}

export interface ContractManagaDetail extends CommonDateNUser {
  contractManageId?: string | number;
  partnerId?: string | number;
  providerId?: string | number;
  contractApplyNumber?: string;
  mbOrganizationId?: string | number;
  spaceProductId?: string | number;
  spaceProductType?: string;
  spaceProductName?: string;
  productCategory?: string;
  isMntProduct?: boolean;
  buildingLocationCode?: string;
  buildingId?: string | number;
  isDeleted?: boolean;
  contractorList?: Array<Contractor>;
  contractName?: string;

  [key: string]: any;
}

export interface Contractor extends CommonDateNUser {
  contractMemberId?: string | number;
  inviteMobileNumber?: string;
  memberNo?: string;
  isContractor?: boolean;
  isAdmin?: boolean;
  inviteDate?: string;
  memberStatus?: string;
  useTime?: number;
  memberPhoneNumber?: string;
  memberEmail?: string;
  isDeleted?: boolean;
  [key: string]: any;
}

export interface ContractBuildingRoom {
  buildingId?: string | number;
  buildingName?: string;
  createdBy?: string;
  createdDate?: string;
  floorId?: string | number;
  floorName?: string;
  floorNum?: number;
  isDeleted?: boolean;
  isGround?: boolean;
  leasableArea?: string;
  leasableAreaNet?: string;
  modifiedBy?: string;
  modifiedDate?: string;
  ownerId?: string | number;
  rent?: string;
  roomId?: string | number;
  roomName?: string;
  roomNum?: number;
}

export interface ContractBuildingRoomForTable extends CommonDateNUser {
  buildingId?: string | number;
  buildingName?: string;
  floorId?: string | number;
  floorName?: string;
  floorNum?: number;
  isDeleted?: boolean;
  isGround?: boolean;
  ownerId?: string | number;
  rent?: string;
  rooms?: string;
}

export const monthDays = [
  { label: "1일", value: 1 },
  { label: "2일", value: 2 },
  { label: "3일", value: 3 },
  { label: "4일", value: 4 },
  { label: "5일", value: 5 },
  { label: "6일", value: 6 },
  { label: "7일", value: 7 },
  { label: "8일", value: 8 },
  { label: "9일", value: 9 },
  { label: "10일", value: 10 },
  { label: "11일", value: 11 },
  { label: "12일", value: 12 },
  { label: "13일", value: 13 },
  { label: "14일", value: 14 },
  { label: "15일", value: 15 },
  { label: "16일", value: 16 },
  { label: "17일", value: 17 },
  { label: "18일", value: 18 },
  { label: "19일", value: 19 },
  { label: "20일", value: 20 },
  { label: "21일", value: 21 },
  { label: "22일", value: 22 },
  { label: "23일", value: 23 },
  { label: "24일", value: 24 },
  { label: "25일", value: 25 },
  { label: "26일", value: 26 },
  { label: "27일", value: 27 },
  { label: "28일", value: 28 },
];

export interface ModalDataWrap {
  commonModals: ModalDatas;
}
export interface ModalDatas {
  popupState: boolean;
  typeString?: string;
  title?: string;
  confirm?: any;
  cancel?: any;
  message?: string;
  subTitle?: string;
}
export const disabledApply: Array<String> = [ContractStep.APPLY_RECEIVED, ContractStep.APPLY_CANCEL, ContractStep.APPLY_CONFIRM];

export const diabledOverdueFee: Array<String> = [
  ContractStep.APPLY_RECEIVED,
  ContractStep.APPLY_CANCEL,
  ContractStep.APPLY_CONFIRM,
  ContractStep.CONTRACT_PLAN,
];
export const disabledIsAutoBilling: Array<String> = [
  ContractStep.APPLY_CANCEL,
  ContractStep.TERMINATE_COMPLETE,
  ContractStep.APPLY_CONFIRM,
  ContractStep.USE_COMPLETE,
];
export const disabledPayRegularDay: Array<string> = [
  ContractStep.APPLY_CANCEL,
  ContractStep.TERMINATE_COMPLETE,
  ContractStep.APPLY_CONFIRM,
  ContractStep.USE_COMPLETE,
];
export const disabledBillingStartEnd: Array<string> = [
  ContractStep.APPLY_CANCEL,
  ContractStep.CONTRACT_ACCEPT,
  ContractStep.USE_APPROVAL,
  ContractStep.USE_PROGRESS,
  ContractStep.TERMINATE_RECEIVED,
  ContractStep.TERMINATE_COMPLETE,
  ContractStep.USE_COMPLETE,
];

export const disabledEsignEdit: Array<string> = [
  ContractStep.APPLY_CANCEL,
  ContractStep.APPLY_RECEIVED,
  ContractStep.APPLY_CONFIRM,
  ContractStep.TERMINATE_COMPLETE,
  ContractStep.USE_COMPLETE,
];

export const disabledBeforeContractPlan: Array<string> = [
  ContractStep.APPLY_RECEIVED,
  ContractStep.APPLY_CONFIRM,
  ContractStep.CONTRACT_PLAN,
  // ContractStep.CONTRACT_ACCEPT,
];
export const disabledBeforeContractAccept: Array<string> = [
  ContractStep.APPLY_RECEIVED,
  ContractStep.APPLY_CONFIRM,
  ContractStep.CONTRACT_PLAN,
  ContractStep.CONTRACT_ACCEPT,
];
export const priceChangeStatus: Array<string> = [
  ContractStep.APPLY_RECEIVED,
  ContractStep.APPLY_CONFIRM,
  ContractStep.CONTRACT_PLAN,
  ContractStep.CONTRACT_ACCEPT,
  ContractStep.USE_APPROVAL,
  ContractStep.USE_PROGRESS,
];
export const priceChangeStatus2: Array<string> = [ContractStep.CONTRACT_ACCEPT, ContractStep.USE_APPROVAL, ContractStep.USE_PROGRESS];

export const disabledScheduleListEdit: Array<string> = [
  ContractStep.APPLY_CANCEL,
  ContractStep.APPLY_RECEIVED,
  ContractStep.APPLY_CONFIRM,
  ContractStep.USE_APPROVAL,
  ContractStep.USE_PROGRESS,
  ContractStep.TERMINATE_RECEIVED,
  ContractStep.TERMINATE_COMPLETE,
  ContractStep.USE_COMPLETE,
];

export const disabledEdit: Array<string> = [
  ContractStep.APPLY_CANCEL,
  ContractStep.APPLY_RECEIVED,
  ContractStep.APPLY_CONFIRM,
  ContractStep.CONTRACT_PLAN,
  ContractStep.USE_COMPLETE,
  ContractStep.TERMINATE_COMPLETE,
];
//계약체결, 이용승인, 이용중, 이용완료, 신청취소
export const disabledScheduleBillEdit: Array<string> = [
  ContractStep.APPLY_CANCEL,
  ContractStep.APPLY_RECEIVED,
  ContractStep.APPLY_CONFIRM,
  ContractStep.CONTRACT_PLAN,
  ContractStep.USE_PROGRESS,
  ContractStep.USE_COMPLETE,
  ContractStep.TERMINATE_RECEIVED,
  ContractStep.TERMINATE_COMPLETE,
];
//계약체결, 이용승인, 이용중, 이용완료, 신청취소
export const s2DisabledEdit: Array<string> = [
  ContractStep.APPLY_CANCEL,
  ContractStep.CONTRACT_ACCEPT,
  ContractStep.USE_APPROVAL,
  ContractStep.USE_PROGRESS,
  ContractStep.USE_COMPLETE,
];
export interface SaveEsignDatas {
  workflowName?: string;
  templateId?: number;
  comment?: string;
  expiryDate?: string;
  recipientList?: Array<ESignRecipient> | Array<ESignRecipientAPIFormat>;
  [key: string]: any;
}
/**
 * 2023-09-07 이후 변경된 API대응을 위한 type 정의
 * 수정 페이지 관련 타입 정의
 * ========================================
 */

/**
 * 2023-10-18 작업
 * RCA02. 계약 신청 (v1.8 이후)
 * POST /api/ctrl/partners/{partnerId}/ct/contract/apply
 */
export interface NewContractSaveModel {
  productId: number;
  isAgreeTerms: boolean;
  useStartTime: string;
  useEndTime: string;
  useTerm?: number;
  contractApplyType: string;
  applicantMemberNo: string;
  applicantMobile: string; //M.164
  applicantName?: string;
  applicantCorporation?: string;
  mbOrganizationId?: number;
  pgcode?: string;
}

//신청계약 공용공간 탭

export interface ProductManage {
  memberNums?: number; // 공간상품 현 이용자수
  memberMaxNums?: number; // 공간상품 최대 이용자수
  isUnlimitedMeetingRoom?: boolean; // 회의실 예약무제한 여부
  isAllowedMeetingRoomOverReservation?: boolean; // 회의실 초과예약 허용 여부
  isLimitedMeetingRoomMonthlyReservation?: boolean; // 회의실 월별 예약제한 사용 여부
  isLimitedMeetingRoomDailyReservation?: boolean; // 회의실 일별 예약제한 사용 여부
  meetingRoomTotalTime?: number; // (공용시설) 회의실 월별 총 예약가능 시간(분 단위)
  meetingRoomDailyTime?: number; // (공용시설) 회의실 일별 총 예약가능 시간(분 단위)
  isUnlimitedRefreshRoom?: boolean; // 리프레쉬룸 예약무제한 여부
  isAllowedRefreshRoomOverReservation?: boolean; // 리프레쉬룸 초과예약 허용 여부
  isLimitedRefreshRoomMonthlyReservation?: boolean; // 리프레쉬룸 월별 예약제한 사용 여부
  isLimitedRefreshRoomDailyReservation?: boolean; // 리프레쉬룸 일별 예약제한 사용 여부
  refreshRoomTotalTime?: number; // (공용시설) 리프레쉬룸 월별 총 예약가능 시간(분 단위)
  refreshRoomDailyTime?: number; // (공용시설) 리프레쉬룸 일별 총 예약가능 시간(분 단위)
  isUnlimitedDesk?: boolean; // 1인 데스크 예약무제한 여부
  isAllowedDeskOverReservation?: boolean; // 1인 데스크 초과예약 허용 여부
  isLimitedDeskMonthlyReservation?: boolean; // 1인 데스크 월별 예약제한 사용 여부
  isLimitedDeskDailyReservation?: boolean; // 1인 데스크 일별 예약제한 사용 여부
  deskTotalTime?: number; // (공용시설) 1인 데스크 월별 총 예약가능 시간(분 단위)
  deskDailyTime?: number; // (공용시설) 1인 데스크 일별 총 예약가능 시간(분 단위)
  isUseMeetingRoom?: boolean; // (공용시설) 회의실 사용여부
  isUseRefreshRoom?: boolean; // (공용시설) 리프레쉬룸 사용여부
  isUseDesk?: boolean; // (공용시설) 데스크 사용여부
  isUseVisitorInvite?: boolean; // 방문자 초대 사용여부
  isUseCalendar?: boolean; // 캘린더연동 사용여부
  dummyUserEmail?: string; // 더미 유저 이메일
  calendarType?: string; // 캘린더 종류 (NONE : 미사용, GC : Google Calendar, OC : Outlook Calendar)
  tenantId?: string; // [캘린더 종류: OC] 테넌트 ID
  clientId?: string; // [캘린더 종류: OC] 클라이언트 ID
  clientSecretValue?: string; // [캘린더 종류: OC] 클라이언트 암호값
  isDeleted?: boolean; // 삭제여부
  createdDate?: string; // 등록일자
  createdBy?: string; // 등록자ID
  modifiedDate?: string; // 수정일자
  modifiedBy?: string; // 수정자ID
  vocUrl?: string; //문의채널
}

export interface ProductFacilityUsedTime {
  meetingRoomMonthlyUsedTime?: number; // (공용시설) 회의실 월 누적 사용시간(분 단위)
  meetingRoomDailyUsedTime?: number; // (공용시설) 회의실 일 누적 사용시간(분 단위)
  refreshRoomMonthlyUsedTime?: number; // (공용시설) 리프레쉬룸 월 누적 사용시간(분 단위)
  refreshRoomDailyUsedTime?: number; // (공용시설) 리프레쉬룸 일 누적 사용시간(분 단위)
  deskMonthlyUsedTime?: number; // (공용시설) 1인 데스크 월 누적 사용시간(분 단위)
  deskDailyUsedTime?: number; // (공용시설) 1인 데스크 일 누적 사용시간(분 단위)
}

export interface ContractFacility {
  contractFacilityId?: string; // 계약 공용시설 ID
  id?: string; // 건물 공용시설 ID (공간상품 관리)
  resourceEmail?: string; // 리소스 캘린더 ID
  isUnlimitedReservation?: boolean; // 예약 무제한 여부
  isAllowedOverReservation?: boolean; // 초과예약 허용 여부
  isLimitedMonthlyReservation?: boolean; // 월별 예약제한 사용 여부
  monthlyServiceMinutes?: number; // 월별 제공시간(분)
  isLimitedDailyReservation?: boolean; // 일별 예약제한 사용 여부
  dailyServiceMinutes?: number; // 일별 예약제한 시간(분)
  isDeleted?: boolean; // 삭제여부
  createdDate?: string; // 등록일자
  createdBy?: string; // 등록자ID
  modifiedDate?: string; // 수정일자
  modifiedBy?: string; // 수정자ID
}

export interface ContractOperationDetail {
  spaceProductId?: string; // 공간상품 ID
  productManage?: ProductManage; // 공간상품 관리 정보
  productFacilityUsedTime?: ProductFacilityUsedTime; // 공용시설 사용 시간 정보
  productFacility?: ContractFacility[]; // 계약 공용시설 배열
}

export interface CtProductFacility {
  contractFacilityId?: string; // 계약 공용시설 ID
  id?: string; // 건물 공용시설 ID (공간상품 관리)
  resourceEmail?: string; // 리소스 캘린더 ID
  isUnlimitedReservation?: boolean; // 예약 무제한 여부
  isAllowedOverReservation?: boolean; // 초과예약 허용 여부
  isLimitedMonthlyReservation?: boolean; // 월별 예약제한 사용 여부
  monthlyServiceMinutes?: number; // 월별 제공시간(분)
  isLimitedDailyReservation?: boolean; // 일별 예약제한 사용 여부
  dailyServiceMinutes?: number; // 일별 예약제한 시간(분)
  isDeleted?: boolean; // 삭제여부
  createdDate?: string; // 등록일자
  createdBy?: string; // 등록자ID
  modifiedDate?: string; // 수정일자
  modifiedBy?: string; // 수정자ID
}

export interface ExpenseProofRequest {
  contractId: number;
  expenseProofType: string;
  identityType: string;
  identityNum: string;
  tradeUsage: string | null;
  taxInvoiceEmail: string | null;
  taxInvoiceManager: string | null;
  taxInvoicePhone: string | null;
  description: string | null;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}

export interface CtBuildingManage {
  buildingManageId: number;
  contractManageId: number;
  buildingId: number;
  isPrimary: boolean;
  isDeleted: boolean;
  createdDate: string;
  createdBy: string;
  modifiedDate: string;
  modifiedBy: string;
}
