import moment from "moment";
import { useEffect, useState } from "react";
import { Cell } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { WorkOrderListAsync } from "src/api/work-order/workorder-api";
import { WorkOrderListModel } from "src/api/work-order/workorder-types";
import { BaseTooltip } from "src/components";
import BaseSelectTable from "src/components/BaseSelectTable";
import { PagePath } from "src/pages/product/details";

import BaseNewTabLink from "../BaseNewTabLink";
import { getContractApply } from "src/api/contract/contract-api";
import { ContractManageList } from "src/api/contract/contract-types";
import { getConnectedInfoListAsync } from "src/api/question/qeustion-api";
import PaymentStatusView from "src/pages/billing/components/PaymentStatusView";
import { BaseContractStatus } from "../BaseContractStatus";
import { ViewYmdFormat } from "src/utils";

const columns: any = [
  {
    Header: "id",
    accessor: "contractId",
    width: 80,
    Cell: ({ value }: { value?: string }) => {
      if (!value) {
        return "-";
      }
      return <BaseNewTabLink path={PagePath.provider.detail.replace(":id", value)} value={value} ellipsis className="ml16" />;
    },
  },

  {
    Header: "상태",
    accessor: "contractStep",
    width: 80,
    Cell: ({ value }: any) => {
      return <BaseContractStatus status={value} />;
    },
  },
  {
    Header: "상품명",
    accessor: "spaceProductName",
    width: 220,
    Cell: ({ value }: any) => {
      return (
        <div className="w-100 text-left">
          <BaseTooltip contents={value} />
        </div>
      );
    },
  },
  {
    Header: "법인명/상호",
    accessor: "mbOrganizationName",
    width: 130,
    sticky: "left",
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
  {
    Header: "계약자명",
    accessor: "applicantName",
    width: 130,
    sticky: "left",
    Cell: ({ value }: any) => {
      return <div className="w-100 text-left">{value ? <BaseTooltip contents={value} /> : "-"}</div>;
    },
  },
  {
    Header: "이용시작일",
    accessor: "contractStartTime",
    width: 120,
    Cell: ({ value }: any) => {
      return <>{value ? moment(value).format(ViewYmdFormat.YYYY_MM_DD) : "-"}</>;
    },
  },
  {
    Header: "이용종료일",
    accessor: "contractEndTime",
    width: 145,
    Cell: ({ value }: any) => {
      return <>{value ? moment(value).format(ViewYmdFormat.YYYY_MM_DD) : "-"}</>;
    },
  },
];
type Props = {
  onClick?: (data: ContractManageList[]) => void;
  onClose?: () => void;
  defaultCheckedList?: string[];
  customColumns?: any[];
};

const SelectContractPopup = ({ onClick, onClose, defaultCheckedList, customColumns }: Props) => {
  const [contracts, setContracts] = useState<Array<ContractManageList>>([]);
  const [defaultDisabledList, setDefaultDisabledList] = useState<string[]>();
  const [pageMeta, setPageMeta] = useState<PageMeta>();

  const { executeAsync: getContractList } = useApiOperation(getContractApply);
  const { executeAsync: getConnectedInfoList } = useApiOperation(getConnectedInfoListAsync);

  const [params, setParams] = useState({
    page: 0,
    size: 20,
    searchType: "All",
    searchValue: "",
    sort: {
      orders: [
        {
          property: "contractManageId",
          direction: "DESC",
        },
      ],
    },
  });

  useEffect(() => {
    const fetchApi = async () => {
      const contractListRes = await getContractList(params);
      // 다른 CS 에서 사용 중인 신청계약ID 비활성화 해제 v1.21
      // 연결된 신청계약 리스트 호출
      // const connectedInfoList = await getConnectedInfoList({ connectedType: "CS_CONNECTED_CONTRACT" }).then((res) =>
      //   res?.data?.data?.content ? res?.data?.data?.content : undefined,
      // );

      // console.log("contractListRes.data.data.data :>> ", contractListRes.data.data.content);

      // setDefaultDisabledList(connectedInfoList?.map((connectedInfo) => connectedInfo.connectedId));
      setContracts(contractListRes?.data?.data?.content);
      setPageMeta(contractListRes?.data?.meta?.pageMeta);
    };

    fetchApi();
  }, [params]);

  return (
    <BaseSelectTable
      setParams={setParams}
      defaultDisabledList={defaultDisabledList}
      defaultCheckedList={defaultCheckedList}
      title="신청/계약 선택"
      selectKey={"contractId"}
      columns={customColumns ? customColumns : columns}
      data={contracts.map((data) => ({ ...data, contractId: String(data.contractId) })) ?? []}
      checkboxType={"radio"}
      onClick={(_, checkedObjList) => onClick && onClick(checkedObjList.map((data) => ({ ...data, contractId: +data.contractId })))}
      onClose={() => onClose && onClose()}
      pageMeta={pageMeta}
      searchInputProps={{
        searchOptionList: [
          { value: "ALL", label: "전체" },
          { value: "CONTRACT_ID", label: "ID" },
          { value: "CONTRACT_APPLY_NUMBER", label: "신청번호" },
          { value: "APPLICANT_CORPORATION", label: "법인명/상호" },
          { value: "SPACE_PRODUCT_NAME", label: "상품명" },
          { value: "APPLICANT_NAME", label: "계약자명" },
        ],

        onSearch(searchKeyword, searchOption) {
          setParams((prev) => ({ ...prev, page: 0, searchValue: searchKeyword, searchType: searchOption }));
        },
      }}
    />
  );
};

export default SelectContractPopup;
