import moment from "moment";
import { useEffect, useState } from "react";
import { Cell } from "react-table";
import { useApiOperation } from "src/api/hooks";
import { PageMeta } from "src/api/public-types";
import { WorkOrderListAsync } from "src/api/work-order/workorder-api";
import { WorkOrderListModel } from "src/api/work-order/workorder-types";
import { BaseTooltip } from "src/components";
import BaseSelectTable from "src/components/BaseSelectTable";
import { PagePath } from "src/pages/product/details";
import { workOrderStatus } from "src/pages/workOrder/workOrder-types";
import BaseNewTabLink from "../BaseNewTabLink";
import { getConnectedInfoListAsync } from "src/api/question/qeustion-api";

const columns: any = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: ({ value }: { value?: string }) => {
      if (!value) {
        return "-";
      }
      return <BaseNewTabLink path={PagePath.provider.detail.replace(":id", value)} value={value} ellipsis className="ml16" />;
    },
  },

  {
    Header: "업무 번호",
    accessor: "workOrderSheetNo",
    width: 200,
    Cell: ({ value }: { value?: string }) => {
      return <div className="w-100 text-center">{value ? <BaseTooltip contents={value.slice(0, -2)} /> : "-"}</div>;
    },
  },
  {
    Header: "상태",
    accessor: "status",
    width: 80,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      let status = workOrderStatus.find((option) => option.value === value)?.label;
      let color = workOrderStatus.find((option) => option.value === value)?.tagColor;
      return <span className={color}>{status}</span>;
    },
  },
  {
    Header: "제목",
    accessor: "summary",
    width: 220,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return <BaseTooltip contents={value} className="w-100 d-flex justfy-content-start" />;
    },
  },
  {
    Header: "담당자",
    accessor: "mgmtOfficerName",
    width: 100,
    Cell: ({ value }: Cell<WorkOrderListModel>) => (
      <div className="w-100 d-flex justfy-content-start">
        <span>{value}</span>
      </div>
    ),
  },
  {
    Header: "업무 시작일",
    accessor: "startDate",
    width: 100,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return (
        <div>
          <span>{value && moment(value).format("YYYY.MM.DD")}</span>
        </div>
      );
    },
  },
  {
    Header: "처리기한",
    accessor: "endDate",
    width: 100,
    Cell: ({ value }: Cell<WorkOrderListModel>) => {
      return (
        <div>
          <span>{value && moment(value).format("YYYY.MM.DD")}</span>
        </div>
      );
    },
  },
];
type Props = {
  partnerId?: string; //TODO: 필요 없으면 관련코드 제거 해주세요.
  onClick?: (data: string[]) => void;
  onClose?: () => void;
  defaultCheckedList?: string[];
};

const SelectWorkOrderPopup = ({ partnerId, onClick, onClose, defaultCheckedList }: Props) => {
  const [workOrders, setWorkOrders] = useState<Array<WorkOrderListModel>>([]);
  const [pageMeta, setPageMeta] = useState<PageMeta>();
  const [defaultDisabledList, setDefaultDisabledList] = useState<string[]>();

  const { executeAsync: getWorkOrderList } = useApiOperation(WorkOrderListAsync);
  const { executeAsync: getConnectedInfoList } = useApiOperation(getConnectedInfoListAsync);

  const [params, setParams] = useState({
    page: 0,
    size: 20,
    keywordList: "",
    sort: {
      orders: [
        {
          property: "id",
          direction: "DESC",
        },
      ],
    },
  });

  useEffect(() => {
    const fetchApi = async () => {
      const workOrderRes = await getWorkOrderList(params);
      // 취소 상태 제거
      const canceledWorkOrderIds = workOrderRes?.data?.data?.content
        ?.filter((workOrder) => workOrder.status === "WORK_ORDER_CANCELED")
        ?.map((data) => data.id ?? "");

      // 다른 CS 에서 사용 중인 워크오더ID 비활성화 해제 v1.21
      // 연결된 워크오더 리스트 호출
      // const connectedInfoList = await getConnectedInfoList({ connectedType: "CS_CONNECTED_WORKORDER" }).then((res) =>
      //   res?.data?.data?.content ? res?.data?.data?.content : undefined,
      // );
      // console.log("connectedInfoList :>> ", connectedInfoList);

      setDefaultDisabledList(canceledWorkOrderIds);
      setWorkOrders(workOrderRes?.data?.data?.content ?? []);
      setPageMeta(workOrderRes?.data?.meta?.pageMeta);
    };

    fetchApi();
  }, [params]);

  console.log("defaultDisabledList :>> ", defaultDisabledList);

  return (
    <BaseSelectTable
      setParams={setParams}
      defaultDisabledList={defaultDisabledList} // 현 or 다른 CS 에 연결된 워크오더는 선택 못하게 처리
      defaultCheckedList={defaultCheckedList}
      title="워크오더 선택"
      selectKey={"id"}
      columns={columns}
      data={workOrders ?? []}
      checkboxType={"checkbox"}
      onClick={(checkedKeyList) => onClick && onClick(checkedKeyList)}
      onClose={() => onClose && onClose()}
      pageMeta={pageMeta}
      searchInputProps={{
        searchOptionList: [
          { value: "", label: "전체" },
          { value: "ID", label: "ID" },
          { value: "NUMBER", label: "워크시트 번호" },
          { value: "SUBJECT", label: "제목" },
          { value: "OFFICER_NAME", label: "담당자명" },
        ],

        onSearch(value, searchOption) {
          setParams((prev) => ({ ...prev, page: 0, keywordList: value, searchType: searchOption }));
        },
      }}
      maxLength={3}
      minLength={1}
    />
  );
};

export default SelectWorkOrderPopup;
