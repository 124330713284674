import moment from "moment";
import { Cell } from "react-table";
import { QuestionAnswerModel, QuestionListModel } from "src/api/question/question-type";
import { BaseTooltip } from "src/components";
import DetailLink from "src/components/DetailLink";
import { PagePath } from "src/pages/product/details";
import { YmdFormat, numberToStringWithComma } from "src/utils";
import { siteOptions, statusOptions } from "../../question-types";

const QuestionColmns = [
  {
    Header: "id",
    accessor: "id",
    width: 80,
    Cell: ({ row, value }: Cell<QuestionListModel>) => {
      const detailPath = `${PagePath.question.detail.replace(":id", value)}`;
      return (
        <DetailLink to={detailPath} className="text-underline">
          {value}
        </DetailLink>
      );
    },
  },
  {
    Header: "상태",
    accessor: "status",
    width: 80,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      let status = statusOptions.find((option) => option.value === value)?.label;
      let color = statusOptions.find((option) => option.value === value)?.tagColor;

      return <span className={color}>{status}</span>;
    },
  },
  {
    Header: "구분",
    accessor: "csCategoryType",
    width: 80,
  },
  {
    Header: "유입경로",
    accessor: "site",
    width: 80,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      const site = siteOptions.find((data) => data.value === value)?.label;
      return <span className="text-center w-100">{site || "-"}</span>;
    },
  },

  {
    Header: "유형",
    accessor: "csTypeName",
    width: 80,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <span>{value || "-"}</span>;
    },
  },

  {
    Header: "항목",
    accessor: "summary",
    width: 80,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <div className="text-left w-full">{value ?? "-"}</div>;
    },
  },
  {
    Header: "접수자 신청/계약",
    accessor: "contractName",
    width: 200,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      //연결된 신청/계약이 있는 경우 계약명 출력, 없으면 하이픈 출력
      //계약명 정보 없으면 ‘상품명' 출력
      return <BaseTooltip className="text-left w-100" contents={value || "-"}></BaseTooltip>;
    },
  },

  {
    Header: "접수자 소속 회사",
    accessor: "mbOrganizationName",
    width: 200,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      //*소속 회사란? 신청/계약의 법인명/상호 정보

      return <BaseTooltip className="text-left w-100" contents={value || "-"}></BaseTooltip>;
    },
  },

  {
    Header: "접수자명",
    accessor: "reporterName",
    width: 280,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      //CS 문의 정보 > 이름/닉네임 출력
      return <BaseTooltip className="text-left w-100" contents={value || "-"}></BaseTooltip>;
    },
  },

  {
    Header: "접수 내용",
    accessor: "description",
    width: 280,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <BaseTooltip className="text-left w-100" contents={value || "-"}></BaseTooltip>;
    },
  },
  {
    Header: "최종 처리자",
    accessor: "completedBy", // 완료처리자
    width: 280,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      //최종 처리자 : 상태값을 ‘완료’로 변경한 파트너 회원 → 이메일 출력
      return <BaseTooltip className="text-left w-100" contents={value || "-"}></BaseTooltip>;
    },
  },
  {
    Header: "답변 내용",
    accessor: "answerList",
    width: 320,
    Cell: ({ row }: Cell<QuestionListModel>) => {
      const answer = row?.original?.answerList?.sort((a: QuestionAnswerModel, b: QuestionAnswerModel) => Number(b.id) - Number(a.id))?.[0]
        .description;

      return <BaseTooltip className="text-left w-100" contents={answer ?? "-"}></BaseTooltip>;
    },
  },
  {
    Header: "건물명",
    accessor: "buildingName",
    width: 120,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <BaseTooltip isSingleLine={false} className="text-left w-full" contents={value ?? "-"} />;
    },
  },

  {
    Header: "세부 위치", // 세부유입경로
    accessor: "siteSummary",
    width: 180,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <BaseTooltip className="text-left w-100" contents={value || "-"}></BaseTooltip>;
    },
  },
  {
    Header: "매출",
    accessor: "cost",
    width: 80,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <span className="text-right w-100">{value !== 0 ? numberToStringWithComma(value) : "-"}</span>;
    },
  },
  {
    Header: "지출",
    accessor: "expense",
    width: 80,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <span className="text-right w-100">{value !== 0 ? numberToStringWithComma(value) : "-"}</span>;
    },
  },
  {
    Header: "처리자", //기존 담당자
    accessor: "assigneeEmail",
    width: 180,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <BaseTooltip className="text-left w-100" contents={value || "-"}></BaseTooltip>;
    },
  },
  {
    Header: "처리 소요시간",
    accessor: "durationTime",
    width: 180,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <BaseTooltip className="text-left w-100" contents={value || "-"}></BaseTooltip>;
    },
  },

  {
    Header: "접수일시",
    accessor: "createDate",
    width: 120,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <BaseTooltip contents={moment(value).format(YmdFormat.YYYY_MM_DD_HH_MM)} type="date" />;
    },
  },
  {
    Header: "최종 처리일시",
    accessor: "completedDate", // 완료처리일시
    width: 120,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      if (value === null) {
        return <span>-</span>;
      }

      return <BaseTooltip contents={moment(value).format(YmdFormat.YYYY_MM_DD_HH_MM)} type="date" />;
    },
  },
  {
    Header: "최종 수정일",
    accessor: "modifiedDate",
    width: 120,
    Cell: ({ value }: Cell<QuestionListModel>) => {
      return <BaseTooltip contents={moment(value).format(YmdFormat.YYYY_MM_DD_HH_MM)} type="date" />;
    },
  },
  {
    Header: "최종 수정자",
    accessor: "modifiedBy",
    width: 120,
    Cell: ({ value, row }: Cell<QuestionListModel>) => {
      if (row.original.site === "SITE_TAAP" || row.original.site === "SITE_TAAP_SPACE") {
        return <BaseTooltip contents={row.original.reporterEmail ?? "-"} />;
      }

      return <BaseTooltip contents={value ?? "-"} />;
    },
  },
];

export default QuestionColmns;
