import { QuestionDetailModel, QuestionStatus } from "src/api/question/question-type";
import { statusOptions } from "./question-types";

export const getFilteredStatusOptionsAndIsDisableSelectButton = (questionDetail?: QuestionDetailModel) => {
  //  수기등록(taap인입이 아닌경우)
  // [접수, 접수중, 보류, 완료]상태의 경우 모든 상태로 변경가능
  // [취소] 상태의 경우 샐랙트버튼 disabled 처리

  // taap인입
  // [접수, 접수중, 보류]상태의 경우 [접수, 접수중, 보류]로만 변경가능 나머지(완료,취소)옵션 disabled 처리
  // [완료, 취소] 상태의 경우 샐랙트버튼 disabled 처리

  // export const statusOptions = [
  //   { label: "전체", value: "" },
  //   { label: "접수", value: QuestionStatus.WAIT,
  //   { label: "처리중", value: QuestionStatus.PROGRESS,
  //   { label: "보류", value: QuestionStatus.HOLD,
  //   { label: "완료", value: QuestionStatus.COMPLETE,
  //   { label: "취소", value: QuestionStatus.CANCELED,
  // ];

  const _statusOptions = statusOptions.filter((item, idx) => idx !== 0);
  const isFromTaap = questionDetail?.site === "SITE_TAAP" || questionDetail?.site === "SITE_TAAP_SPACE";
  const status = questionDetail?.status;
  let isDisableSelectButton = false;
  let filteredStatusOptions: any = _statusOptions;
  //
  if (isFromTaap) {
    //taap인입
    if (status === QuestionStatus.WAIT || status === QuestionStatus.PROGRESS || status === QuestionStatus.HOLD) {
      // 현재 상태가 [접수, 접수중, 보류]상태의 경우 [완료,취소]옵션 disabled 처리
      filteredStatusOptions = _statusOptions.map((status) => {
        if (status.value === QuestionStatus.COMPLETE || status.value === QuestionStatus.CANCELED) {
          return { ...status, isDisabled: true };
        } else {
          return status;
        }
      });
    } else {
      //현재 상태가 [완료, 취소] 상태의 경우 샐랙트버튼 disabled 처리
      isDisableSelectButton = true;
    }
  } else {
    //수기등록(taap인입이 아닌경우)
    if (status === QuestionStatus.CANCELED) {
      //현재 상태가 취소상태인 경우
      isDisableSelectButton = true;
    }
  }

  return { filteredStatusOptions, isDisableSelectButton };
};
